@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
html {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
body {
  background: #F4F5FA;
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

div, input {
  box-sizing: border-box;
}


button, input, select {
  font-family: 'Rubik', sans-serif;
}
input:focus, select:focus, button:focus {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #F4F5FA;
}

::-webkit-scrollbar-thumb {
  background: #6b6f82;
}

::-webkit-scrollbar-thumb:hover {
  background: #474956;
}